.motif-icons,
.motif-icons-rendered {
  list-style: none;
  margin: 0;
  padding: 0;
}

.motif-icons-rendered li {
  margin-bottom: 8px;

  img {
    width: 80px;
  }
}

.page-header {
  padding-top: 12px;
}

.filters {
  padding-top: 24px;
  padding-bottom: 24px;
}

.motif-source {
  text-decoration: none;
}
